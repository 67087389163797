// header
var isSubMenuOpen = false;
const subMenu = document.querySelector('.sub-menu');
const menuButton = document.getElementById('menu-button');
const menu = document.getElementById('headerNavMenu');
const subMenuButton = document.getElementById('subMenuOpen');

const reverseAnimation = document.querySelector('#reverse');

function menuOpen() {
    if (window.matchMedia("(max-width: 910px)").matches) {
        return false;
        // viewport width matches max-width: 910px media query
    } else {
        // viewport width does not match max-width: 910px media query 

        subMenu.style.transition = "all 0.5s ease-in-out";
        if (!isSubMenuOpen) {
            isSubMenuOpen = true;
            subMenu.classList.add("open");

        } else {
            subMenu.classList.remove("open");
            isSubMenuOpen = false;
        }
    }
}
// create an event listener for outside clicks

subMenuButton.addEventListener('click', (e) => {
    e.preventDefault();
    menuOpen();
});


var isMenuOpen = false;
function spMenuOpen() {
    menu.style.transition = "all 0.5s ease-in-out";
    if (!isMenuOpen) {
        menu.classList.add("open");
        isMenuOpen = true;
        setTimeout(() => {
            document.addEventListener('click', onOutsideMenuClick);
        }, 100);

    } else {
        menu.classList.remove("open");
        isMenuOpen = false;
        document.removeEventListener('click', onOutsideMenuClick);
    }
}
function onOutsideMenuClick(event) {
    if (!event.target.closest('#headerNavMenu')) {
        spMenuOpen();
        reverseAnimation.beginElement();
    }
}

menuButton.addEventListener('click', spMenuOpen);

// footer
(function () {
    window.addEventListener('load', function () {

        const subPageOnlyMenu = document.getElementById('subPageOnlyMenu');
        const bodyClass = document.body.classList;
        if (bodyClass.contains('top')) {
            subPageOnlyMenu.style.display = 'none';
        }
        else {
            subPageOnlyMenu.style.display = 'block';
        }
    })
})();


// animation

gsap.registerPlugin(ScrollTrigger);


const mainList = [...document.querySelectorAll(".features-container ul"), ...document.querySelectorAll(".reason-container ul"), ...document.querySelectorAll(".footerNavList"), ...document.querySelectorAll(".faq-container ul"), ...document.querySelectorAll(".flow-container ul")];

// console.log(mainList);

mainList.forEach((list) => {
    const listItems = list.querySelectorAll("li");
    gsap.set(listItems, {
        opacity: 0,
        scale: 0.9,
    });
});


ScrollTrigger.defaults({
    // markers: true
})

function animate(list) {
    const items = list.querySelectorAll("li");
    gsap.to(items, {
        opacity: 1,
        scale: 1,
        duration: 0.5,
        stagger: 0.1, // Adjust the stagger value as per your requirement
    });
}

mainList.forEach((list) => {
    ScrollTrigger.create({
        trigger: list,
        start: "top 90%",
        end: "bottom 50%",
        once: true,
        onEnter: () => {
            animate(list)
        },
    });
});